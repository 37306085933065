import React from 'react';
import {
    Route,
    RouteProps,
    RouteComponentProps,
    Redirect,
    Switch
} from 'react-router-dom';
import { SemanticICONS } from 'semantic-ui-react';

export interface IRoute extends RouteProps {
    path?: string;
    component?: React.FunctionComponent<RouteComponentProps<any>>;
    layout?: React.FunctionComponent;
    icon?: SemanticICONS;
    label?: string;
    to?: string;
    tooltip?: any;
    childRoutes?: Omit<IRoute, 'layout'>[];
    href?: string;
}

type IRouteNode = {
    layout: string;
    path?: string;
    component: React.FunctionComponent;
    children: React.ReactNode[];
};

const renderRouter = (route: IRoute) => {
    if (route.layout) {
        const node: IRouteNode = {
            layout: route.layout.displayName ?? route.layout.name,
            path: route.path,
            component: route.layout,
            children: [] as any,
        };

        // Main component handler
        if (route.path && route.component && !route.to) {
            node.children.push(<Route key={route.path} {...route} exact />);
        } else if (route.path && route.to) {
            node.children.push(
                <Redirect
                    key={route.path}
                    from={route.path}
                    to={route.to}
                    exact
                />
            );
        }

        if (route.childRoutes) {
            node.children = node.children.concat(
                route.childRoutes.map((value) =>
                    renderRouter({ ...value } as IRoute)
                )
            );
        }

        return node;
    }

    if (route.path && route.to) {
        return (
            <Redirect key={route.path} from={route.path} to={route.to} exact />
        );
    }

    // Children component handler
    return <Route key={route.path} {...route} />;
};

const routerFactory = (
    routes: IRoute[],
    defaultPage: React.FC
): React.ReactNode => {
    const result = routes.map((route) => renderRouter(route)) as IRouteNode[];

    const entries: Record<string, Omit<IRouteNode, 'layout'>> = result.reduce(
        (items, item) => {
            const list = items;
            const listItem = item;
            if (!list[listItem.layout]) {
                list[listItem.layout] = {
                    path: listItem.path ?? '/', // We will get the shortest path as the main for the group
                    component: listItem.component,
                    children: listItem.children,
                };

                return list;
            }

            list[listItem.layout] = {
                ...list[listItem.layout],
                path:
                listItem.path &&
                listItem.path.length < list[listItem.layout].path.length
                        ? listItem.path
                        : list[listItem.layout].path,
                children: [...list[listItem.layout].children, ...listItem.children],
            };

            return list;
        },
        {}
    );

    return Object.entries(entries).map(([key, value]: [string, any]) => (
        <Route key={key} path={value.path}>
            <value.component>
                <Switch>
                    {value.children}
                    <Route component={defaultPage} />
                </Switch>
            </value.component>
        </Route>
    ));
};

export default routerFactory;
