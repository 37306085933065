import PrivateLayout from 'src/layouts/private/private.component';
import routerFactory, { IRoute } from 'src/libraries/router.library';
import Error from 'src/components/pages/error/error-404/error.component';
import Home from 'src/components/pages/home/home.component';

/**
 * This will centralized all the routes for this application,
 * which makes them easier to access and maintain.
 * This will also avoid route duplications.
 */
const MainRoute: IRoute = {
    path: '/',
    layout: PrivateLayout,
    component: Home,
};

export const routes = [MainRoute];
export type IRouter = typeof routes[number];

export default routerFactory(routes, Error);
