import React from 'react';
import Lang from 'src/libraries/language';

type IProps = {
    error?: any;
};

const Error: React.FC<IProps> = ({ error }) => {
    if (error) {
        return (
            <div>
                {error}
                <a href="/">{Lang.MSG_ERROR_CLICK}</a>
            </div>
        );
    }

    return (
        <div>
            <h2>{Lang.TTL_ERROR_404}</h2>
            <p>{Lang.MSG_ERROR_PAGE}</p>
            <a href="/">{Lang.MSG_ERROR_CLICK}</a>
        </div>
    );
};

export default Error;
