export default {
    TTL_APPLICATION: 'Qualifying Facility Information',
    TTL_COGENERATION_AND_SMALL_POWER_PRODUCTION_REPORT:
        'Cogeneration & Small Power Production Report',
    TTL_ERROR_404: 'Error 404',
    TTL_SDGE_HISTORICAL_SRAC_SUMMARIES: "SDGE's Historical SRAC Summaries",
    TTL_SHORT_RUN_AVOIDED_COST_REPORT: 'Short Run Avoided Cost Report',
    TTL_WELCOME: 'Welcome',
    TTL_USER_AGENT: "It looks like you may be using a web browser that we don't support.",
};
