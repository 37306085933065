export enum ReportType {
  SRAC = 'S',
  GLHS = 'G',
  TLHS = 'T',
  PLHS = 'P',
  CSPP = 'C',
}

export const ExcelFileType = [
  'xls',
  'xlsx',
  'csv',
  'ods',
  'dif',
  'tsv',
  'xlm',
  'xlsb',
  'xlsm',
  'xlt',
  'xltm',
  'xltx',
];

export const PDFFileType = ['pdf'];

export enum FileType {
  XLS = 'xls',
  PDF = 'pdf',
}
