export default {
  MSG_COGENERATION_AND_SMALL_POWER_PRODUCTION_REPORT:
    'This report is updated annually (if necessary) and describes the status' +
    ' of QFs who have contracts with SDGE and any changes to their status since the ' +
    'last report.',
  MSG_COPYRIGHT: 'Copyright',
  MSG_ERROR_CLICK: 'Click to return home.',
  MSG_ERROR_PAGE: 'Page not found.',
  MSG_FOOTER:
    'All content posted on this website provided for information purposes only,' +
    ' and SDG&E is not acting as an advisor in connection therewith.',
  MSG_LAST_UPDATED: ' - Last updated {0}',
  MSG_SDGE_HISTORICAL_SRAC_SUMMARIES:
    "These reports are updated monthly and contain a summary of SDGE's historical " +
    'energy and capacity SRAC. These reports are informational only and are NOT an ' +
    'official posting of the SRAC.',
  MSG_SHORT_RUN_AVOIDED_COST_REPORT:
    "These reports are published monthly and summarize SDGE's SRAC paid to QFs.",
  MSG_USER_CREATE_SUCCESS: 'User was successfully created!',
  MSG_WELCOME_MESSAGE:
    'The Public Utility Regulatory Policies Act of 1978 ("PURPA") requires utilities ' +
    'to interconnect with and purchase power from Qualifying Facilities ("QFs") at rates ' +
    "that do not exceed SDGE's avoided cost. QFs are non-utility generators who use many " +
    'types of technologies such as cogeneration, bio-gas, bio-mass, geothermal, ' +
    'hydroelectric, wind, solar, photovoltaic, etc. SDGE has QFs interconnected to its ' +
    'electric grid and purchases energy and capacity from them. SDGE pays for this energy ' +
    'and capacity at prices approved by the California Public Utilities Commission. These ' +
    'prices are known as SDGE\'s Short Run Avoided Costs ("SRAC").',
  MSG_USER_AGENT:
    'Please use one of the supported browsers - Latest Version of Google Chrome or Microsoft Edge to get the optimal experience',
};
