import React from 'react';
import moment from 'moment';
import {
  MOLInfoText,
  ATMContainer,
  ATMSegment,
  ATMHeader,
  ATMTable,
  ATMFooter,
  ATMIcon,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import {
  getFilesByReportType,
  IXMLFile,
  getFileByFileType,
} from 'src/libraries/helper.library';
import { FileType, ReportType } from 'src/constants';
import style from './home.module.scss';

type IProps = {
  data?: IXMLFile[];
};

const HomeView: React.FC<IProps> = ({ data }) => {
  if (!data) {
    return null;
  }

  const list: Record<ReportType, IXMLFile[]> = {
    [ReportType.SRAC]: [], // Just to remove TS error
    [ReportType.GLHS]: [],
    [ReportType.TLHS]: [],
    [ReportType.PLHS]: [],
    [ReportType.CSPP]: [],
  };

  const MultiList: IXMLFile[][] = getFilesByReportType(data, ReportType.SRAC)
    .sort((x, y) =>
      moment(y.Metadata.reportdate).diff(moment(x.Metadata.reportdate))
    )
    .slice(0, 6)
    .reduce((result: IXMLFile[][], item, index) => {
      const files = result;
      const chunkIndex = Math.floor(index / 2);

      if (!files[chunkIndex]) {
        files[chunkIndex] = [];
      }

      files[chunkIndex].push(item);

      return files;
    }, []);

  list[ReportType.GLHS] = getFilesByReportType(data, ReportType.GLHS);
  list[ReportType.TLHS] = getFilesByReportType(data, ReportType.TLHS);
  list[ReportType.PLHS] = getFilesByReportType(data, ReportType.PLHS);
  list[ReportType.CSPP] = getFilesByReportType(data, ReportType.CSPP);

  const reportTable = (blobs: IXMLFile[]) => {
    const pdfFile = getFileByFileType(blobs, FileType.PDF);
    const xlsFile = getFileByFileType(blobs, FileType.XLS);

    if (!pdfFile) {
      return null;
    }

    return (
      <React.Fragment key={pdfFile?.Properties.Etag}>
        <ATMTable.Row>
          <ATMTable.Cell style={{ width: '80%' }}>
            <a
              style={{ textDecoration: 'underline' }}
              href={`${pdfFile?.Url}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {pdfFile?.Metadata.displayname}
            </a>
          </ATMTable.Cell>
          {xlsFile ? (
            <ATMTable.Cell>
              <a
                style={{ color: 'black' }}
                href={`${xlsFile.Url}`}
                download={xlsFile.Metadata.displayname}
              >
                <ATMIcon name="download" />
                {Lang.LBL_EXCEL_FILE}
              </a>
            </ATMTable.Cell>
          ) : (
            <ATMTable.Cell></ATMTable.Cell>
          )}
        </ATMTable.Row>
      </React.Fragment>
    );
  };

  const lastUpdated = (report) => {
    let file = report;
    if (file.length) {
      file = file.sort((x, y) =>
        moment(y.Properties['Last-Modified']).diff(
          moment(x.Properties['Last-Modified'])
        )
      );

      return moment(file[0].Properties['Last-Modified']).format('MMMM YYYY');
    }

    return '';
  };
  return (
    <ATMContainer className={style.wrapper}>
      <MOLInfoText
        header={Lang.TTL_WELCOME}
        content={Lang.MSG_WELCOME_MESSAGE}
      />

      <ATMSegment color="purple">
        <ATMHeader as="h2">
          {Lang.TTL_SHORT_RUN_AVOIDED_COST_REPORT}
          <ATMHeader.Subheader>
            {Lang.MSG_SHORT_RUN_AVOIDED_COST_REPORT}
          </ATMHeader.Subheader>
        </ATMHeader>
        <ATMTable>
          <ATMTable.Header>
            <ATMTable.Row>
              <ATMTable.HeaderCell style={{ width: '80%' }}>
                {Lang.LBL_REPORTS}
              </ATMTable.HeaderCell>
              <ATMTable.HeaderCell>{Lang.LBL_ACTION}</ATMTable.HeaderCell>
            </ATMTable.Row>
          </ATMTable.Header>
          <ATMTable.Body>
            {MultiList.map((value) => reportTable(value))}
          </ATMTable.Body>
        </ATMTable>
      </ATMSegment>

      <ATMSegment color="purple">
        <ATMHeader as="h2">
          {Lang.TTL_SDGE_HISTORICAL_SRAC_SUMMARIES}
          <i>
            {Lang.formatString(
              Lang.MSG_LAST_UPDATED,
              lastUpdated([
                ...list[ReportType.GLHS],
                ...list[ReportType.TLHS],
                ...list[ReportType.PLHS],
              ])
            )}
          </i>
          <ATMHeader.Subheader>
            {Lang.MSG_SDGE_HISTORICAL_SRAC_SUMMARIES}
          </ATMHeader.Subheader>
        </ATMHeader>
        <ATMTable>
          <ATMTable.Header>
            <ATMTable.Row>
              <ATMTable.HeaderCell style={{ width: '80%' }}>
                {Lang.LBL_REPORTS}
              </ATMTable.HeaderCell>
              <ATMTable.HeaderCell>{Lang.LBL_ACTION}</ATMTable.HeaderCell>
            </ATMTable.Row>
          </ATMTable.Header>
          <ATMTable.Body>
            {reportTable(list[ReportType.GLHS])}
            {reportTable(list[ReportType.TLHS])}
            {reportTable(list[ReportType.PLHS])}
          </ATMTable.Body>
        </ATMTable>
      </ATMSegment>

      <ATMSegment color="purple">
        <ATMHeader as="h2">
          {Lang.TTL_COGENERATION_AND_SMALL_POWER_PRODUCTION_REPORT}
          <i>
            {Lang.formatString(
              Lang.MSG_LAST_UPDATED,
              lastUpdated(list[ReportType.CSPP])
            )}
          </i>
          <ATMHeader.Subheader>
            {Lang.MSG_COGENERATION_AND_SMALL_POWER_PRODUCTION_REPORT}
          </ATMHeader.Subheader>
        </ATMHeader>
        <ATMTable>
          <ATMTable.Header>
            <ATMTable.Row>
              <ATMTable.HeaderCell style={{ width: '80%' }}>
                {Lang.LBL_REPORT}
              </ATMTable.HeaderCell>
              <ATMTable.HeaderCell>{Lang.LBL_ACTION}</ATMTable.HeaderCell>
            </ATMTable.Row>
          </ATMTable.Header>
          <ATMTable.Body>{reportTable(list[ReportType.CSPP])}</ATMTable.Body>
        </ATMTable>
      </ATMSegment>

      <div className={style.footer}>
        <div>&nbsp;{`${Lang.MSG_COPYRIGHT}`}&nbsp;&nbsp;</div>
        <ATMFooter
          customized
          content={Lang.MSG_FOOTER}
          appName="San Diego Gas &amp; Electric Company."
        />
      </div>
    </ATMContainer>
  );
};

export default HomeView;
