import DictionaryLang from 'src/libraries/language/en/dictionary.language';
import LabelLang from 'src/libraries/language/en/label.language';
import MessageLang from 'src/libraries/language/en/message.language';
import TitleLang from 'src/libraries/language/en/title.language';

import LocalizedStrings from 'react-localization';

const LangConsts = {
    en: {
        ...DictionaryLang,
        ...LabelLang,
        ...MessageLang,
        ...TitleLang,
    } as const,
} as const;

const Lang = new LocalizedStrings(LangConsts);

export default Lang;
