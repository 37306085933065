import React from 'react';
import { ORGHeader, ATMSkipLinks, MOLUserAgent } from 'shared-it-appmod-ui';
import { routes } from 'src/router';
import Lang from 'src/libraries/language';
import styles from './private.module.scss';

const MasterLayout: React.FC = ({ children }) => {
    const data = [
        {
            id: 'navigation',
            text: 'Skip to navigation',
        },
        {
            id: 'content',
            text: 'Skip to content',
        },
    ];
    return (
        <>
            <ATMSkipLinks data={data} />
            <MOLUserAgent
            header={Lang.TTL_USER_AGENT}
            content={Lang.MSG_USER_AGENT}
            />
            <ORGHeader
                showUser={false}
                application={Lang.TTL_APPLICATION}
                environment={process.env.REACT_APP_ENVIRONMENT}
                menus={routes
                    .filter((value) => value.label)
                    .map((route) => ({
                        name: route.label,
                        to: route.path,
                    }))}
            >
                <div className={styles.wrapper}>{children}</div>
            </ORGHeader>
        </>
    );
};

export default MasterLayout;
