import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ATMLoader } from 'shared-it-appmod-ui';
import { getParsedFileXML, IXMLFile } from 'src/libraries/helper.library';
import Lang from 'src/libraries/language';
import Error from 'src/components/pages/error/error-404/error.component';
import HomeView from './home.view';

const Home = () => {
  const [state, setState] = useState<IXMLFile[]>();
  const baseURL = process.env.REACT_APP_API_UPLOAD_BLOB;

  useEffect(() => {
    axios
      .create({
        headers: { 'Content-Type': 'application/xml; charset=utf-8' },
        baseURL,
      })
      .get('')
      .then((response) => {
        const data = getParsedFileXML(response.data);

        setState(data);

        return data;
      })
      .catch((error) => {
        return <Error error={error} />;
      });
  }, [setState, baseURL]);

  return (
    <>
      {!state ? (
        <ATMLoader active>{Lang.LBL_LOADING_REPORT_INFORMATION}</ATMLoader>
      ) : (
        <HomeView data={state} />
      )}
    </>
  );
};

export default Home;
