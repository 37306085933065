import xml2js from 'xml2js';
import { isObject } from 'lodash';
import {
  ExcelFileType,
  FileType,
  PDFFileType,
  ReportType,
} from 'src/constants';

export type IFileMeta = {
  displayname?: string;
  reporttype?: ReportType;
  reportdate?: string;
  filename?: string;
};

export type IXMLFile = {
  Metadata: IFileMeta;
  Name: string;
  Url: string;
  Properties: {
    BlobType: string;
    'Cache-Control': string;
    'Content-Encoding': string;
    'Content-Language': string;
    'Content-Length': string;
    'Content-MD5': string;
    'Content-Type': string;
    Etag: string;
    'Last-Modified': string;
    LeaseStatus: string;
  };
};

const cleanXML = (xml) => {
  const result: Record<string, any> = {};

  if (isObject(xml)) {
    Object.entries(xml).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((val) => {
          result[key] = cleanXML(val);
        });
      } else {
        result[key] = value;
      }
    });
  } else {
    return xml;
  }

  return result;
};

export const getParsedFileXML = (xml) => {
  let files: IXMLFile[] = [];

  if (xml) {
    xml2js.parseString(xml, (error, result) => {
      if (!error) {
        files = result.EnumerationResults.Blobs[0].Blob.map((value) =>
          cleanXML(value)
        );
      }
    });
  }

  return files;
};

export const getFilesByReportType = (
  files: IXMLFile[] = [],
  type: ReportType
) => files.filter((value) => value.Metadata.reporttype === type);

export const getFileType = (filename) => {
  const type = filename.split('.');

  return type[type.length - 1].toLowerCase();
};

export const getFileByFileType = (files: IXMLFile[] = [], type: FileType) =>
  files.find((value) =>
    (type === FileType.PDF ? PDFFileType : ExcelFileType).includes(
      getFileType(value.Name)
    )
  );
